import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DocumentsEnum } from '@app/core/enums/documents.enum';
import { WINDOW } from '@inshared/shared/util';
import { StorageService } from '@app/core/storage/universal.inject';

/** @deprecated - this is moved to libs */
@Component({
    selector: 'ins-cookie-settings-wall-modal',
    templateUrl: 'cookie-settings-wall-modal.component.html',
    styleUrls: ['./cookie-settings-wall-modal.component.scss'],
})
export class CookieSettingsWallModalComponent {
    public cookieForm: UntypedFormGroup = new UntypedFormGroup({
        cookieSettings: new UntypedFormControl('', [Validators.required]),
    });

    public toggle = {
        cookieSettings: false,
        explanation: false,
    };

    public documentsEnum = DocumentsEnum;

    constructor(
        @Inject(StorageService) private storage: Storage,
        @Inject(WINDOW) private window: Window,
    ) { }

    public onSubmit(): void {
        if (this.cookieForm.invalid) {
            this.saveSettings('compleet');
            return;
        }

        this.saveSettings(this.cookieForm.value.cookieSettings);
    }

    public toggleSettings(): void {
        this.toggle.cookieSettings = !this.toggle.cookieSettings;
        this.cookieForm.reset({
            cookieSettings: '',
        });
    }

    public openCookieLink(): void {
        if (this.cookieForm.invalid) {
            this.saveSettings('basis');
        }
    }

    private saveSettings(cookieSettings: string): void {
        this.storage.setItem('ls.cookie.setting', cookieSettings);
        // After cookie is set, reload page
        this.window.location.reload();
    }
}
