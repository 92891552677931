import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CardModule } from '@app/content/shared/card/card.module';
import { CynoFormModule } from '@app/shared/cyno-form/cyno-form.module';
import { PdfLinkModule } from '@app/shared/pdf-link/pdf-link.module';
import { ListModule } from '@app/shared/ux/lists/list.module';
import { NewModalModule } from 'outshared-lib';
import { CookieSettingsWallModalComponent } from './cookie-settings-wall-modal.component';

/**
 * @deprecated replace with CookieSettingsWallModule from '@inshared/shared/feature-cookie'
 */
@NgModule({
    declarations: [
        CookieSettingsWallModalComponent,
    ],
    imports: [
        CommonModule,
        NewModalModule,
        CynoFormModule,
        CardModule,
        ListModule,
        RouterModule,
        PdfLinkModule,
    ],
    exports: [
        CookieSettingsWallModalComponent,
    ],
    providers: [],
})
export class CookieSettingsWallModule { }
