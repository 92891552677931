<h2 modal-title>Cookies</h2>
<div modal-content>
    <p>
        InShared gebruikt cookies om u beter en persoonlijker te helpen als u contact met ons heeft. Bijvoorbeeld via de
        website, sociale media of advertenties op andere websites.
    </p>
    <!-- prettier-ignore -->
    <p>
        Dat doen wij door het analyseren en combineren van uw online gedrag met persoonsgegevens die u online achterlaat
        en bij ons bekend zijn. Wij werken hiervoor samen met andere bedrijven die cookies plaatsen. Ook buiten Europa.
        Lees meer over <a href="/cookies" (click)="openCookieLink()">cookies</a> of ons
        <a href="/privacy" (click)="openCookieLink()">privacystatement</a>.
    </p>

    <h3>Gaat u akkoord?</h3>
    <p>
        U geeft dan toestemming voor het plaatsen van analytische, affiliate, feedback, advertentie en personalisatie
        cookies en het verwerken van uw persoonsgegevens. Wilt u dit niet? Dan kunt u zelf uw cookie instellingen
        aanpassen. Basis cookies zonder invloed op uw privacy plaatsen wij altijd.
    </p>

    <div class="cookie-settings__panel" *ngIf="toggle.cookieSettings">
        <h3>Instellingen</h3>
        <!-- prettier-ignore -->
        <p>
            Onze website maakt gebruik van
            <ins-pdf-link [pdfLink]="documentsEnum.CookieOverview"
                          pdfLinkTitle="Cookie overzicht">3 soorten cookies</ins-pdf-link>.
            U kunt zelf een keuze maken. Die keuze kunt u aanpassen via de link “Cookie instellingen aanpassen”
            onderaan de website. Zonder de functionele cookies zal de website niet goed werken, daarom kunnen deze niet
            gedeactiveerd worden.
        </p>
        <h3>
            Instellingen veranderen
            <span *ngIf="cookieForm.value.cookieSettings">({{ cookieForm.value.cookieSettings | titlecase }})</span>
        </h3>
        <form
            class="form-horizontal cookie-settings__form"
            [formGroup]="cookieForm"
            role="form"
            (ngSubmit)="onSubmit()"
        >
            <cyno-field [inline]="false">
                <cyno-radio formControlName="cookieSettings" value="compleet" id="cookiesettings_compleet">
                    Ik wil functionele, analytische, affiliate, feedback, advertentie en personalisatie cookies
                    (compleet)
                </cyno-radio>
            </cyno-field>
            <cyno-field [inline]="false">
                <cyno-radio formControlName="cookieSettings" value="uitgebreid" id="cookiesettings_uitgebreid">
                    Ik wil functionele en analytische cookies (uitgebreid)
                </cyno-radio>
            </cyno-field>
            <cyno-field [inline]="false">
                <cyno-radio formControlName="cookieSettings" value="basis" id="cookiesettings_basis">
                    Ik wil alleen functionele cookies (basis)
                </cyno-radio>
            </cyno-field>

            <ng-container *ngIf="cookieForm.value.cookieSettings">
                <ins-card-rack>
                    <ins-card [borders]="true">
                        <ins-card-head>
                            <h4>Wat doen we wel</h4>
                        </ins-card-head>
                        <ins-card-content>
                            <list-flat icon="check" [svg]="true">
                                <list-flat-item>Cookies gebruiken zodat deze website goed werkt</list-flat-item>
                                <list-flat-item>Anonieme statistieken bijhouden</list-flat-item>
                                <list-flat-item>Een premieberekening en/of aanmelding meten</list-flat-item>
                                <list-flat-item
                                    *ngIf="
                                        cookieForm.value.cookieSettings === 'uitgebreid' ||
                                        cookieForm.value.cookieSettings === 'compleet'
                                    "
                                >
                                    Gebruikersonderzoek
                                </list-flat-item>
                                <list-flat-item *ngIf="cookieForm.value.cookieSettings === 'compleet'">
                                    Persoonlijke statistieken bijhouden
                                </list-flat-item>
                                <list-flat-item *ngIf="cookieForm.value.cookieSettings === 'compleet'">
                                    Informatie op maat tonen
                                </list-flat-item>
                                <list-flat-item *ngIf="cookieForm.value.cookieSettings === 'compleet'">
                                    Adverteren op andere websites en sociale media, o.a. vanuit informatie over uw
                                    bezoek
                                </list-flat-item>
                            </list-flat>
                        </ins-card-content>
                    </ins-card>
                    <ins-card [borders]="true" *ngIf="cookieForm.value.cookieSettings !== 'compleet'">
                        <ins-card-head>
                            <h4>Wat doen we niet</h4>
                        </ins-card-head>
                        <ins-card-content>
                            <list-flat icon="check" [svg]="true">
                                <list-flat-item *ngIf="cookieForm.value.cookieSettings === 'basis'">
                                    Gebruikersonderzoek
                                </list-flat-item>
                                <list-flat-item>Persoonlijke statistieken bijhouden</list-flat-item>
                                <list-flat-item>Informatie op maat tonen</list-flat-item>
                                <list-flat-item>
                                    Gepersonaliseerd adverteren op basis van uw websitebezoek
                                </list-flat-item>
                            </list-flat>
                        </ins-card-content>
                    </ins-card>
                </ins-card-rack>
            </ng-container>
        </form>
    </div>
</div>

<ng-container>
    <div modal-actions alignment="between">
        <div class="cookie-settings__button-footer">
            <button type="submit" (click)="onSubmit()" class="btn btn--primary" id="footer-button-left">
                Ik ga akkoord
            </button>
        </div>
        <div class="cookie-settings__button-footer">
            <button
                type="submit"
                (click)="toggleSettings()"
                class="btn btn--secondary btn--dropdown"
                id="footer-button-right"
            >
                Instellingen aanpassen
                <svg *ngIf="toggle.cookieSettings" class="btn__icon svg-icon svg-icon--xs">
                    <use href="assets/icons-ui.svg#pijl-up-bold"></use>
                </svg>
                <svg *ngIf="!toggle.cookieSettings" class="btn__icon svg-icon svg-icon--xs">
                    <use href="assets/icons-ui.svg#pijl-down-bold"></use>
                </svg>
            </button>
        </div>
    </div>
</ng-container>
